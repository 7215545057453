import { useEffect, useState } from "react";
import { env } from "../tools/utils/env.tool";

export function extractEnvironment(url: string): string | null {
  const regex = /https:\/\/api-(.*?)\.memorable\.io/;
  const match = url.match(regex);
  return match ? match[1] : null;
}

export const useVersionFeature = () => {
  const [currentVersion, setCurrentVersion] = useState("0.0.0");

  const LAST_DEPLOY_DATE = "2024-10-28T15:43:41.232Z";

  useEffect(() => {
    let isMounted = true;

    const fetchVersion = async () => {
      try {
        const pkg = await import("../../../package.json");
        if (isMounted) {
          setCurrentVersion(pkg.version);
        }
      } catch (error) {
        // console.error("Error fetching package.json:", error);
      }
    };

    fetchVersion();

    return () => {
      isMounted = false;
    };
  }, []);

  const envUrl = env("VITE_GRAPHQL_ENDPOINT");
  const envName = extractEnvironment(envUrl)?.toUpperCase();

  const getCurrentVersion = () => currentVersion;
  const getEnvironment = () => envName;
  const getLastDeployDate = () => new Date(LAST_DEPLOY_DATE);

  return {
    getCurrentVersion,
    getEnvironment,
    getLastDeployDate,
  };
};
