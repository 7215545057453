import { useEffect } from "react";
import { Toaster } from "react-hot-toast";

import { env } from "./tools/utils/env.tool";

import { useSessionFeature } from "./features/session/session.feature";

import { useBrandsDomain } from "src/domain/brands/brands.domain";
import { useUsersDomain } from "src/domain/users/users.domain";
import { Brand } from "src/graphql/client";
import useSWR from "swr";
import { AppRoutes } from "./app.routes";
import { useLdcIdentifyUser } from "./features/feature-flags/feature-flags.feature";
import { LoadingPage } from "./features/requests/features/loadings/pages/loading.page";
import { useRequestFeature } from "./features/requests/request.feature";
import { LOCAL_STORAGE_BRAND_ID } from "./pages/creative-intelligence-suite/widgets/header/widgets/brand-selector.widget";

declare global {
  interface Window {
    Intercom: any;
  }
}

const App = () => {
  const {
    user,
    currentBrand,
    getUser,
    setUser,
    selectBrand,
    isUserImpersonated,
  } = useSessionFeature();

  const { skipLoadingExtraData, getUserHash } = useUsersDomain();

  const { sendRequest } = useRequestFeature();

  const { data: loggedUser, isLoading: isLoadingUser } = useSWR("getUser", () =>
    sendRequest({
      id: "getUser",
      request: getUser(),
    }),
  );

  const { data: userHash } = useSWR(loggedUser ? "getUserHash" : null, () =>
    sendRequest({
      id: "getUserHash",
      request: getUserHash(),
    }),
  );

  const getSelectedBrandIdFromLocalStorage = () => {
    return localStorage.getItem(LOCAL_STORAGE_BRAND_ID);
  };

  const doesBrandBelongToUser = (brandId: string, userBrands: Brand[]) => {
    return userBrands.some((userBrand) => userBrand.id === brandId);
  };

  const { getUsersBrand } = useBrandsDomain();
  const { data: brands, isLoading: isLoadingBrand } = useSWR(
    user?.brandPermissions &&
      user?.businessAccount &&
      skipLoadingExtraData(user)
      ? `BrandSelectorWidget-getUsersBrand-${user.id}`
      : null,
    () =>
      sendRequest({
        id: `BrandSelectorWidget-getUsersBrand-${user.id}`,
        request: getUsersBrand(user),
      }),
  );

  useEffect(() => {
    if (!brands?.length) return;
    const storedSelectedBrandId = getSelectedBrandIdFromLocalStorage();
    if (
      storedSelectedBrandId &&
      doesBrandBelongToUser(storedSelectedBrandId, brands)
    ) {
      const selectedBrand = brands.find(
        (brand) => brand.id === storedSelectedBrandId,
      );
      if (selectedBrand) {
        selectBrand(selectedBrand);
      }
    } else {
      const defaultSelectedBrand = brands[brands.length - 1];
      selectBrand(defaultSelectedBrand);
    }
  }, [brands]);

  useEffect(() => {
    setUser(loggedUser);
  }, [loggedUser]);

  // Sending Launchdarkly user and brand information
  const { launchDarklyIdentified } = useLdcIdentifyUser({
    user,
    currentBrand,
    isLoadingUser,
  });

  // handling Intercom
  useEffect(() => {
    const APP_ID = env("VITE_INTERCOM_APP_ID");
    if (user && !isUserImpersonated && currentBrand && userHash) {
      window.Intercom("boot", {
        app_id: APP_ID,
        name: user.name,
        email: user.email,
        user_hash: userHash,
        created_at: new Date().getTime(),
      });
    } else {
      window.Intercom("shutdown");
    }
  }, [user, isUserImpersonated, currentBrand, userHash]);

  const isLoading = isLoadingUser || isLoadingBrand;

  const isWaitingForLaunchDarkIdentify =
    !!user && !!currentBrand && !launchDarklyIdentified;

  if (isLoading || isWaitingForLaunchDarkIdentify) {
    return <LoadingPage />;
  }

  return (
    <>
      <AppRoutes />
      <Toaster />
    </>
  );
};

export default App;
